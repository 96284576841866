
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarContaContabilReferenciada from '@/components/Cadastros/PlanosContas/SelecionarContaContabilReferenciada.vue';
import SelecionarPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarPlanoConta.vue';
import { IDTOPlanoContaCategoriaContaContabilReferenciada } from '@/models/DTO/Cadastros/PlanosContas/IDTOPlanoContaCategoriaContaContabilReferenciada';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import ServicoPlanoContas from '@/servicos/Cadastros/PlanosContas/ServicoPlanoContas';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';

export default defineComponent({
  name: 'VinculacaoContaContabilReferenciadaModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    MensagemSemDados,
    SelecionarEmpresaCadastroCompartilhado,
    SelecionarPlanoConta,
    SelecionarContaContabilReferenciada,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, apresentarMensagemErro, apresentarMensagemAlerta,
    } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoPlanoContas = new ServicoPlanoContas();

    telaBase.identificadorRecurso = 'CADASTRO_PLANOS_CONTAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PLANOS_CONTAS';

    let debounce = 0;
    const state = reactive({
      categoriasDb: [] as IDTOPlanoContaCategoriaContaContabilReferenciada[],
      categorias: [] as IDTOPlanoContaCategoriaContaContabilReferenciada[],
      checks: [] as boolean[],
      todosMarcados: false,
      codigoPlanoConta: 0,
      filtro: '',
      contaSelecionada: 0,
      descricaoContaSelecionada: '',
    });

    function objetoInicial() {
      state.categoriasDb = [] as IDTOPlanoContaCategoriaContaContabilReferenciada[];
      state.categorias = [] as IDTOPlanoContaCategoriaContaContabilReferenciada[];
      state.checks = [] as boolean[];
      state.todosMarcados = false;
      state.filtro = '';
      state.contaSelecionada = 0;
      state.descricaoContaSelecionada = '';
    }

    function preencherChecks() {
      state.categorias.forEach((c) => {
        state.checks.push(false);
      });
    }

    function marcarDesmarcarTodos(evento: any) {
      state.checks.forEach((c, index) => {
        state.checks[index] = evento.target.checked;
      });
    }

    function filtrar() {
      telaBase.carregando = true;
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        state.categorias = await servicoPlanoContas.obterCategoriascontaContabilReferenciada(state.codigoPlanoConta, telaBase.empresasSelecionadas[0], state.filtro);
        preencherChecks();
        telaBase.carregando = false;
      }, 600);
    }

    function definir() {
      let qtdMarcados = 0;
      state.checks.forEach((c, index) => {
        if (c) {
          const indice = state.categoriasDb.findIndex((c) => c.codigo === state.categorias[index].codigo);
          state.categoriasDb[indice].codigoContaContabilReferenciada = state.contaSelecionada;
          state.categoriasDb[indice].nomeContaContabilReferenciada = state.descricaoContaSelecionada;
          state.checks[index] = false;
          qtdMarcados += 1;
        }
      });
      state.todosMarcados = false;
      if (qtdMarcados === 0) {
        apresentarMensagemAlerta('Nenhuma categoria foi selecionada.');
      }
    }

    function desvincular() {
      let qtdMarcados = 0;
      state.checks.forEach((c, index) => {
        if (c) {
          const indice = state.categoriasDb.findIndex((c) => c.codigo === state.categorias[index].codigo);
          state.categoriasDb[indice].codigoContaContabilReferenciada = 0;
          state.categoriasDb[indice].nomeContaContabilReferenciada = '';
          state.checks[index] = false;
          qtdMarcados += 1;
        }
      });
      state.todosMarcados = false;
      if (qtdMarcados === 0) {
        apresentarMensagemAlerta('Nenhuma categorias foi selecionada.');
      }
    }

    async function atualizarCategorias() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoPlanoContas.atualizarVinculoCategoriasPlanoContas(telaBase.empresasSelecionadas, state.categoriasDb);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function buscarCategorias() {
      telaBase.carregando = true;
      state.categoriasDb = await servicoPlanoContas.obterCategoriascontaContabilReferenciada(state.codigoPlanoConta, telaBase.empresasSelecionadas[0]);
      state.categorias = state.categoriasDb;

      preencherChecks();
      telaBase.carregando = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      objetoInicial();
      if (modalBase.computedVisivel) {
        await obterPermissoes(ETipoPermissao.Dados);
        await preencherEmpresasComEstrategiaPermissaoDados(EPermissaoDados.Alterar, true);
        await defineEmpresasSelecionadasComPermissao();

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      buscarCategorias,
      apresentarRetornoRequisicao,
      objetoInicial,
      EPermissaoDados,
      filtrar,
      definir,
      atualizarCategorias,
      desvincular,
      marcarDesmarcarTodos,
    };
  },
});
